import React, {ReactNode, createContext, useContext, useCallback} from 'react';
import {WidgetArea, MountChildren, WidgetChildren} from '../types';

interface MountingProviderProps {
  children: ReactNode;
  areas: WidgetArea[];
  mountChildren: MountChildren;
}

interface MountingContext {
  mountChildren: MountChildren;
  getWidgetProps: (name: string, src: string) => WidgetChildren | undefined;
}

const MountingContext = createContext<MountingContext>({
  mountChildren: () => () => {},
  getWidgetProps: (name: string, src: string) => undefined
});

export const useMounting = () => useContext(MountingContext);

export const MountingProvider = ({children, areas, mountChildren}: MountingProviderProps) => {
  const getWidgetProps = useCallback((name: string, src: string) => {
    return areas.find((item) => item.name === name)?.children.find((item) => item.src === src);
  }, [areas]);

  return <MountingContext.Provider value={{mountChildren, getWidgetProps}}>{children}</MountingContext.Provider>;
};
