/** @jsxRuntime classic */
/** @jsx jsx */
import {css} from '@emotion/react';
export const fieldsCSS = css`
    span {
      font-size: calc(14rem / var(--bfs));
      line-height: 18px;
      color: var(--text-color);
      width: 75%;
    }
`;