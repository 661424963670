/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react';
import {Fragment} from 'react';
import {Skeleton} from '../components/Skeleton';
import {avatarCSS} from './Avatar.styles';

interface AvatarProps {
  picture: string;
  isLoading: boolean;
}

export const Avatar = ({picture, isLoading}: AvatarProps) => {
  return (
    <div
      className="userpage__avatar"
      css={avatarCSS}
      style={{borderColor: isLoading ? 'transparent' : 'var(--primary-color)'}}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Fragment>
          <div className="userpage__avatar-hover">
            <span>Загрузить фото</span>
          </div>
          <input type="file" accept="image/png, image/jpeg, image/webp, image/svg+xml" />
          {picture ? <img src={picture} /> : <i className="mdi mdi-account" />}
        </Fragment>
      )}
    </div>
  );
};
