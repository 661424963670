/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx, css} from '@emotion/react';

const skeletonCSS = css`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  &:after {
    content: '';
    animation: skeleton-animation 1.2s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, rgba(88, 92, 98, 0), rgba(88, 92, 98, 0.4), rgba(88, 92, 98, 0));
  }

  @keyframes skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const Skeleton = () => {
  return <div className="skeleton" css={skeletonCSS} />;
};
