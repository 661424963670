/** @jsxRuntime classic */
/** @jsx jsx */
import {css} from '@emotion/react';

export const avatarCSS = css`
  width: calc(134rem / var(--bfs));
  height: calc(134rem / var(--bfs));
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .mdi-account {
    font-size: calc(60rem / var(--bfs));
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .userpage__avatar-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    text-align: center;
    background: rgba(41, 66, 131, 0.4);
    backdrop-filter: blur(7.5px);
    transition: opacity 200ms ease;

    span {
      font-size: calc(14rem / var(--bfs));
      line-height: 18px;
      color: var(--text-color);
      width: 75%;
    }
  }
  &:hover .userpage__avatar-hover {
    opacity: 1;
    transition: opacity 200ms ease;
  }
`;
