/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from '@emotion/react';
import React, {MutableRefObject, useEffect, useRef} from 'react';
import {useMounting} from '../../hooks/useMounting';
import {fieldsCSS} from './Fields.styles';

interface Props {
  onEdit: (newEntity: Record<'localeSwitch', string>) => void;
  entityLocale: string | null;
  fields: Field[];
  schemaJson: Schema;
  locale: Record<string, Record<string, Record<string, string>>>;
}
interface Field {
  title: string;
  type: string;
  class: string;
  options: Array<{
    title: string;
    value: string;
  }>;
}

interface Schema {
  type: string;
  required: string[];
  properties: {
    localeSwitch: {
      type: string;
    };
  };
}

const Fields = ({onEdit, entityLocale, fields, locale, schemaJson}: Props) => {
  const mountingPointRef: MutableRefObject<HTMLDivElement> = useRef(null!);
  const {mountChildren, getWidgetProps} = useMounting();

  useEffect(() => {
    const fieldConfig = getWidgetProps('widget-vue-fields', '@netvision/widget-vue-fields');
    const props = {
      ...fieldConfig,
      props: {
        ...fieldConfig?.props,
        entity: {localeSwitch: entityLocale ?? 'ru'},
        entityType: 'userProfile',
        fields,
        locale,
        schemaJson,
        onEdit,
      }
    };

    return mountChildren(mountingPointRef.current, [props]);
  }, [entityLocale, fields, getWidgetProps, locale, mountChildren, onEdit, schemaJson]);

  return (
    <div className="fields-adapter single-spa-container userpage__localeform" ref={mountingPointRef} css={fieldsCSS} />
  );
};

const FieldsMemo = React.memo(Fields);
export {FieldsMemo as Fields};
