import React, {ReactNode, createContext, useContext, useState, useEffect} from 'react';
import {CubeQuery} from '@netvision/lib-api-repo';
import {useApiRepository} from './useApiRepository';
import {UserInfo} from '../types';

interface DataProviderProps {
  children: ReactNode;
}

interface DataContext {
  user: UserInfo;
  isLoading: boolean;
}

const DataContext = createContext<DataContext>({
  user: {} as UserInfo,
  isLoading: true
});

export const useData = () => useContext(DataContext);

export const DataProvider = ({children}: DataProviderProps) => {
  const {api} = useApiRepository();
  const [user, setUser] = useState<UserInfo>({} as UserInfo);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchUserData = async () => {
    if (!('cubeGetEntities' in api) || api.cubeGetEntities === undefined) {
      throw new Error("cubeGetEntities method isn't implemented");
    }

    setIsLoading(true);

    try {
      const {userInfo} = await api.checkLoggedIn();
      const {entity: user} = await api.getEntity<UserInfo>({
        type: 'User',
        id: userInfo.userId
      })
      setUser({...userInfo, ...user});
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return <DataContext.Provider value={{user, isLoading}}>{children}</DataContext.Provider>;
};
