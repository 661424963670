import React, {createContext, ReactNode, useContext, useState} from 'react';
import {GlobalEventBus} from '@netvision/lib-types-frontend';

interface ObserverProviderProps {
  eventBusID: string;
  eventBus: GlobalEventBus;
  children: ReactNode;
}

const ObserverContext = createContext<Omit<ObserverProviderProps, 'children'>>({
  eventBusID: '',
  eventBus: {} as GlobalEventBus
});

export const useObserver = () => useContext(ObserverContext);

export const ObserverProvider = ({eventBusID, eventBus, children}: ObserverProviderProps) => {
  const [eBusID] = useState<string>(eventBusID);
  const [eBus] = useState<GlobalEventBus>(eventBus);

  return <ObserverContext.Provider value={{eventBus: eBus, eventBusID: eBusID}}>{children}</ObserverContext.Provider>;
};
