import React from 'react';
import {WidgetConfig} from './types';
import {ApiRepositoryProvider} from './hooks/useApiRepository';
import {ObserverProvider} from './hooks/useEventBus';
import {DataProvider} from './hooks/useData';
import {MountingProvider} from './hooks/useMounting';
import {Main} from './components/Main';

export const Root = ({
  props: {eventBus, eventBusID, lib, initView},
  mountChildren,
  areas
}: WidgetConfig) => {
  return (
    <ApiRepositoryProvider lib={lib}>
      <ObserverProvider eventBusID={eventBusID} eventBus={eventBus}>
        <MountingProvider mountChildren={mountChildren} areas={areas}>
          <DataProvider>
            <Main initView={initView} />
          </DataProvider>
        </MountingProvider>
      </ObserverProvider>
    </ApiRepositoryProvider>
  );
};
