/** @jsxRuntime classic */
/** @jsx jsx */
import {css} from '@emotion/react';

export const userpageCSS = css`
  display: flex; 
  flex-direction: column;
  height: 100%;
  .userpage__header {
    display: flex;
    align-items: flex-end;
    gap: calc(37rem / var(--bfs));

    &-preloader {
      position: relative;
      height: calc(125rem / var(--bfs));
      width: calc(250rem / var(--bfs));
      border-radius: var(--border-radius);
      overflow: hidden;
    }

    &-login {
      margin-bottom: calc(20rem / var(--bfs));

      label {
        font-size: calc(16rem / var(--bfs));
        line-height: calc(20rem / var(--bfs));
        color: var(--text-color-secondary);
      }

      h2 {
        margin: 0;
        font-weight: 500;
        font-size: calc(24rem / var(--bfs));
        line-height: calc(30rem / var(--bfs));
        color: var(--text-color);
      }
    }
  }
`
